#about3 {
    /* height:100vh;
     padding-top: 7rem;
     overflow:visible;*/
     /* padding-top: 5rem;*/
     background-color: #FFFFFF;
     font-family: 'Libre Baskerville', sans-serif !important;
 }
 
 .about3__container {
     text-align: center;
     height:100%;
     position:relative;
     display: flex;
     justify-content: space-between;
     align-items: flex-start;
     gap:1rem; 
     
     /*padding-bottom: 20rem;*/
     padding-bottom: 3rem;
     padding-top: 2rem;
     
 }
 
 .about3Explainer{
     flex:1;
     padding:5%;
   
    width: 100%;
 }

 .about3Explainer div{
    position: relative;
     top:7rem;
     display:flex;
     flex-direction:column;
     justify-content: center;
     align-items: flex-end;
     gap:2.1rem;
    width:max-content;
    text-align: left; 
    
 }

 .about3Explainer p{
  color:#40255F;
  font-size: 27px;
  font-weight: 600;
  width:100% 
 }

 
 .about3CTA{
     flex:2;
     position: relative;
     
     display:flex;
     flex-direction: column;
     
 }
 
 .CTAbox{
     background-color: var(--color-white);
     height: 480px;
     width: 400px;
     padding:5%;
     z-index: 100;
 }

 .CTA3boximg{
 
/*height:600px;*/
width:100%;
 aspect-ratio: 1.3 /1;
 }
 
 .btn-place{
     position: relative;
     top:40%;
     /*this positioning isnt exactly right but we'll  come back to it*/
 }



.about-btn-placement {

    display:flex;
    justify-content:center;
    align-items:center;
    gap:3rem;
    position: relative;
    top:3.5px
   
   }


   .mobileComparisons{
    display: none;
   }

 
 .clip-path-bottom-right{
   background: #0767A880;
  
  clip-path: ellipse(100% 97% at 100% 97%);
   height: 100px;
   width:100px; 
 
   /*positioning of the quarter circle*/
   position:absolute;
   bottom:13%;
   right:-10%;
   z-index: 0;
   /*positioning END*/
 }
 
 
 
 .clip-path-bottom-left{
     background: var(--color-primary-variant) !important;
  
    clip-path: ellipse(100% 97% at 100% 97%);
     height: 100px;
     width:100px; 
 
 
 /*positioning of the quarter circle*/
 position:absolute;
 bottom:13%;
 left:-10%;
 z-index: 0;
   /*positioning END*/
   }
 
   
 
   .clip-path-top-left{
     background:var(--color-primary-variant);
   
    clip-path: ellipse(100% 97% at 0% 97%);
     height: 100px;
     width:100px; 
 
     /*positioning of the quarter circle*/
     position:absolute;
     top:-5%;
     left:-2%;
     z-index: -1;
 
     /*positioning END*/
   }
 
 /* ================ CTA ===============*/
 .cta {
     margin-top: 2.5rem;
     display:flex;
     gap:1rem; /*you need to add gap to your arsenal, you're still using margins inside flex and that sucks*/
     justify-content: center;
 
 }
 
 
 /* ================ about SOCIALS ===============*/
 
 .about__socials {
     display:flex;
     flex-direction:column;
     align-items: center;
     gap: 0.8rem;
 
     /*this is how we get those funky placements that accentuate a website*/
     position: absolute;
     left:0;
     bottom: 3rem;
 }
 
 /*notice how to do lines and shapes using "after pseudo element*/
 
 .about__socials::after{ /*i believe after and before are usuially just boxes , but it is our sizing and rotating of them that will make them into different shapes*/
  content:'';
  width:1px;
  height:2rem;
  background: var(--color-primary);
 
 }
 
 
 /* ================ ME ===============*/
 
 /*.me {
     background: linear-gradient(var(--color-primary),55% ,transparent);
     width:24rem; 
     height:32rem;
     position:absolute;
 
    
     left:calc(50% - 11rem);
     
 
     margin-top:4rem;
     border-radius:12rem 12rem 0 0;
     overflow:visible;
     padding:1rem 1.5rem 1.5rem 1.5rem;
 }*/
 
 /* ================ SCROLL DOWN ===============*/
 
 .scroll__down {
     position:absolute;
     right:-2.3rem;
     bottom:5rem;
     transform:rotate(90deg);
     font-weight:500;
     font-size:1.1rem;
 }



  /* ======================== MEDIA QUERIES FOR about (KINDA LARGER DEVICES) ======================*/
 
  @media screen and (max-width:1800px) {
    
    .about3__container {
      
       gap:1rem;
       /*padding-top: 0rem; */
    }


    .CTA3boximg{
 
       /* height:650px;*/
       width:100%;
         /*aspect-ratio: 2.5 /1*/;
         }



         .about3Explainer{
            flex:1;
           width:5%;    
        }



    .about3Explainer div{
        position: relative;
         top:7.2rem;
         gap:2.6rem;
        
        
        }


        .about3Explainer p{
          font-size: 1.5rem;
           
            
            }
    


        .about3CTA{
            flex:2.5
        }

        
 
 
  }

  @media screen and (max-width:1700px) {
    .about3Explainer div{
        position: relative;
         top:7.2rem;
         gap:2.4rem;
        
        
        }

  }


 @media screen and (max-width:1650px) {
  
    .about3Explainer div{
        position: relative;
         top:6.5rem;
         gap:2.5rem;
        
        
        }


        .about3Explainer p{
          font-size:21.5px;/* 1.35rem;*/
           
            
            }

 }

 @media screen and (max-width:1550px) {
    .about3Explainer div{
        position: relative;
         top:6.5rem;
         gap:2.1rem;
        
        
        }
 }
 
 
 /* ======================== MEDIA QUERIES FOR about (KINDA LARGE DEVICES) ======================*/
 
 @media screen and (max-width:1500px) {
    
    .about3__container {
      
       gap:1rem;
        
    }

    .about3CTA{
        flex:2.5
    }
    
    .about3Explainer{
        flex:1;
       width:5%;
      
       
    }

    .about3Explainer div{
        position: relative;
         top:4.5rem;
         gap:1.5rem;
       
        
        }


        .about3Explainer p{
          font-size: 17px;
           
            
            }

            .CTA3boximg{
 
                height:500px;
                /*width:100%;*/
                 aspect-ratio: 1/1.3;
                 }
    

        
 
 
  }

   /* ======================== MEDIA QUERIES FOR HEADER CHANGING TO COLUMN DISPLAY ======================*/

 @media screen and (max-width:1300px) {

    .about3__container {
        /*flex-direction: column !important;*/
        align-items: center;
    }


    .mobileComparisons {
        display:inline-block;
    }

    .about3Explainer{
        
       width:100%;
      
       
    }


    .about3Explainer p {
        font-size: 16px;
    }

    .about3CTA {
        flex:2.5;
    }

    .CTA3boximg{
 
        height:500px;
        /*width:100%;*/
         aspect-ratio: 1/1.3;
         }
         



    .about3Explainer div{
        position: relative;
         top:3.7rem;
        width:100%;
       gap:1.2rem;
       
        
        }

.about-btn-placement {
    flex-direction: column;
    gap:1rem;
}
    
 }


 @media screen and (max-width:1230px) {

    .about3__container {
       /* flex-direction: column;*/
        gap:2rem;
      /*margin-top: 35rem !important;*/
         padding-bottom: 1rem;
         padding-top: 1rem;
     }




     .about3Explainer div{
        position: relative;
         top:3.5rem;
        width:100%;
       
        gap:1rem;
        
        }


        .about3Explainer p {
            font-size: 14px;
        }

 

     .CTA3boximg{
 
        height:400px;
        /*width:100%;*/
         aspect-ratio: 1/1.3;
         }




 }



 @media screen and (max-width:1170px) {

    .about3Explainer div{
        position: relative;
         top:3.2rem;
        width:100%;
       gap:0.7rem;
     
        
        }

 }





 @media screen and (max-width:1100px) {

    .about3__container {
       /* flex-direction: column;*/
        gap:2rem;
      /*margin-top: 35rem !important;*/
         padding-bottom: 1rem;
         padding-top: 1rem;
         display: none;
     }

     .howAreWeDiff {
        display: none;
     }




     .about3Explainer div{
        position: relative;
         top:3.8rem;
        width:100%;
        
        gap:1.2rem;
        
        }


        .about3Explainer p {
            font-size: 14px;
        }

 

     .CTA3boximg{
 
        height:400px;
        /*width:100%;*/
         aspect-ratio: 1/1.3;
         }




 }
 
 /* ======================== MEDIA QUERIES FOR about (MEDIUM DEVICES) ======================*/
 
 @media screen and (max-width:1024px) {
    
    .about3__container {
       flex-direction: column;
       gap:8rem;
     /*margin-top: 35rem !important;*/
        padding-bottom: 1rem;
        padding-top: 1rem;
        
    }

    .about3__decor {
        display: none;
    }

    .mobileComparisons {
        display:inline-block;
    }

    .respectively {
       position: relative;
       top:5rem;
    }
 
 

    .CTA3boximg{
 
        height:650px;
         aspect-ratio: 1.6 /1;
         }

         .about3Explainer div{
            position: relative;
             top:2rem;
         }
  }
  
  
  
  /* ======================== MEDIA QUERIES FOR about (SMALL DEVICES) ======================*/
  
  @media screen and (max-width:600px) {
      
    .about3Explainer div{
        position: relative;
         top:2rem;
      }
     
      .about__socials, .scroll__down{
         display:none;
      }

      .about3__container {
       
     /* margin-top: 40rem !important;*/
         
     }


     .CTA3boximg{
 
        height:300px;
         aspect-ratio: 1.3 /1;
         }
   
   }
 