#choice {
    /*background: var(--color-bg-variant);*/
    margin-bottom: 4rem;
    font-family: 'Libre Baskerville', sans-serif !important;
}


.choice__container {
    /*display:grid;                         */
    /*grid-template-columns: 1fr  1fr 1fr;*/
    /*gap:0rem;*/

       display:flex;
        justify-content: center;
        align-items: flex-start;
        
        gap:5rem;
        margin-top: 2rem;
       
}

.choice__container  > div {
  background:transparent;
  /*padding:2.8rem 3rem;*/
  padding-top:3.5rem;
  
 /* border:  var(--separator-line);*/
  border-radius: 5%;
  transition:var(--transition);
 
 /* box-shadow: var(--feature-shadow);
-webkit-box-shadow: var(--feature-shadow);
-moz-box-shadow: var(--feature-shadow);*/
}


.choice__container  > div:hover {
    background: transparent;
   /*border-color:var(--color-primary-variant);*/
    
    cursor:default;
}


.large-desc > p {
    background-color:#40255F ;
    color:"#FFFFFF";
    padding:2.4rem 1rem;
    border-radius: 1rem;
    height:max-content;
    display:flex;
    justify-content: flex-start;
    align-items: center;

}



.choice__container > div h3 {
  text-align:left;
  margin-bottom: 2rem;
 
}

.word__container {
    grid-row: span 2;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap:1.5rem;
}

.choice__backend {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap:0px;
    width:30rem;
   

}


.choice-small-image {
 /*flex:2;*/
 position: relative;
 top:5%;

}

.large-desc {
   /* flex:5;*/
   text-align: center;
   display:flex;
   flex-direction: column;
   justify-content: flex-start;
   align-items: center;
   width:100%;
}



.choice-small-image img {
    height:80px;
    width:110px;
}

.choice__content {
   display:grid;
   grid-template-columns: 1fr 1fr;
   row-gap:2rem;
}

.choice__details {
    display:flex;
    gap:1rem;

}

.choice__details-icon {
    margin-top: 6px;
    color:var(--color-primary);

}


.btn-pos {
   margin-top: 5%;
}


/*============== MEDIA QUERIES (KINDA LARGE DEVICES) ======================= */

@media screen and (max-width:1500px) {
    .choice__container {
        /*grid-template-columns:1fr  1fr 1fr ;*/
        /*gap:0rem;*/

        display:flex;
        justify-content: center;
        align-items: flex-start;
       gap:2rem;
    }

    .choice__container > div h3 {
        text-align:center;
        margin-bottom: 2rem;
       
      }

      .choice-small-image {
        /*flex:2;*/
        position: relative;
        margin:0 auto;
       
       }
}

/*============== MEDIA QUERIES (MEDIUM DEVICES (FOR OUR MOTIVATION SECTION)) ======================= */

@media screen and (max-width:1200px) {
    .choice__container {
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
     
    }


    .choice__backend {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap:0px;
        width:45rem; 
    
    }
}


/*============== MEDIA QUERIES (MEDIUM DEVICES) ======================= */

@media screen and (max-width:1024px) {
    .choice__container {
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
     
       
    }

    .choice__container > div {
        width:80%;
        padding:2rem;
        margin:0 auto;
    }

    .choice__content {
        padding: 1rem;
    }
}


/*============== MEDIA QUERIES (SMALL DEVICES) ======================= */

@media screen and (max-width:600px) {
    .choice__container {
        
        gap: 0rem;

    }

    .choice__container > div {
        width:100%;
        padding:2rem 1rem;

    }




}

 




